import React, { useEffect } from "react";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import "../styles/pages/_lp.scss";
import convo1 from "../video/TSD_convo1.mp4";
import convo2 from "../video/TSD_convo2.mp4";

const OneHourConversation = () => {
  useEffect(() => {
    const convo2 = document.querySelector("#convo2");
    var convo1played2 = false;
    document.addEventListener("scroll", () => {
      if (window.scrollY >= convo2.offsetTop - 500 && !convo1played2) {
        convo2.play();
        convo1played2 = true;
      }
    });
  }, []);

  return (
    <Layout type="template-lp">
      <SEO
        title="Change Your Business Trajectory in 1 Hour"
        description="A 1-hour conversation with a Fractional CMO can help you avoid costly mistakes and look at the big picture when planning your launch."
      />

      <section className="cmo-lp-convo convo1">
        <div className="container wrapper">
          <video id="convo1" className="convo-video" muted autoPlay playsInline>
            <source src={convo1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="content-wrapper">
            <h3>
              1 hour that will change the trajectory of your brand and business.
            </h3>
            <p>
              A 1-hour call with a Fractional CMO can help you{" "}
              <strong>avoid costly mistakes</strong> and look at the big picture
              when planning your launch.
            </p>
            <p>
              A Fractional CMO will look at your launch from all angles and
              suggest scalable options to give your launch the best chance for
              success.
            </p>
          </div>
        </div>
      </section>
      <section className="cmo-lp-convo convo2">
        <div className="container wrapper">
          <div className="content-wrapper">
            <h3>Access Advice and Insight You Never Could Before.</h3>
            <p>
              A Fractional CMO{" "}
              <strong>helps identify the various moving pieces</strong> you
              might need for a successful launch and helps right-size the
              resources you will need to make the impact you need – or refocus
              your goals.
            </p>
            <p>
              A Fractional CMO can{" "}
              <strong>help you guide internal or external team leaders</strong>{" "}
              like creator partnership managers, designers, social media
              managers, paid media strategists and more to deliver a highly
              successful launch strategy.
            </p>
          </div>
          <video id="convo2" className="convo-video" muted playsInline>
            <source src={convo2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </section>

      <section className="convo-form-section">
        <div className="container wrapper">
          <h3>
            Our conversations and planning can get you from stuck, to go and
            from meh to woah!
          </h3>
          <div className="white-button">
            <span>BOOK YOUR 1-HOUR CONSULTATION TODAY</span>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default OneHourConversation;
